








import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import SystemApi from '@/services/api/System';

@Component
class SystemDetailsComponent extends Vue {
    private static notFoundMessage = 'Details not found';
    private static serverErrorMessage = 'Error retrieving details';

    public details: object | null;
    public error: boolean = false;
    public errorMsg!: string;

    @Prop()
    public systemId!: string;

    public constructor() {
        super();
        this.details = null;
    }

    public mounted() {
        if (this.systemId) {
            SystemApi.getDetails(this.systemId)
                .then(response => {
                    this.details = response.data.system;
                })
                .catch(error => {
                    this.error = true;
                    if (error.response && error.response.status === 404) {
                        this.errorMsg = SystemDetailsComponent.notFoundMessage;

                    } else {
                        this.errorMsg = SystemDetailsComponent.serverErrorMessage;
                    }

                });
        }
    }
}

export default SystemDetailsComponent;
