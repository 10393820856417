




































import Vue from 'vue';
import SystemDetails from './Details.vue';
import SystemDownloads from './Downloads.vue';
import { Component, Prop } from 'vue-property-decorator';
import { SystemInterface } from '@/interfaces/SystemInterface';
import RemoveSystem from '@/components/common/RemoveSystem.vue';
import SystemLicensingDialog from '@/components/LicensingReport/SystemLicensingDialog.vue';

@Component({
    components: {
        RemoveSystem,
        SystemDetails,
        SystemDownloads,
        SystemLicensingDialog,
    },
})
class SystemDetailsPaneComponent extends Vue {
    public name: string = 'systemDetails';
    public dialog: boolean = false;
    public processing: boolean = false;
    public showResponse: boolean = false;
    public progressBarMessage: string = '';
    @Prop({ type: Object as () => SystemInterface })
    public system!: SystemInterface;

    public isStorNextUpgradeAvailable(): boolean {
        return ['StorNext Software', 'Appliance'].includes(this.system.type);
    }

    public get isLicenseDialogActive(): boolean {
        return this.system.type !== 'Connect';
    }

    public systemListChanged(): void {
        this.$emit('systemListChanged');
    }
}


export default SystemDetailsPaneComponent;
