






















































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

interface DownloadEntry {
    [id: string]: string;
}

@Component
class FilteredDownload extends Vue {

    @Prop()
    public readonly filterList?: any;
    @Prop()
    public readonly downloadList?: DownloadEntry[];

    public myFilterList?: any;
    public filterArray: any;
    public myDownloadList?: DownloadEntry[];
    public filteredSelection: any;
    public selectedFilter: any[];
    public filteredDownloadList?: any = null;
    public filters: { [name: string]: any };
    public disableClearFilters: boolean = true;
    public activeSelectKey: number = 0;

    public constructor() {
        super();
        this.filterArray = {};
        this.myFilterList = this.filterList;
        this.myDownloadList = this.downloadList;
        this.filteredSelection = {};
        this.selectedFilter = [];
        this.filters = [];
    }

    /**
     * Sort an array of mixed numbers and other
     *
     * Numbers are sorted per numeric rules, everything else is smaller than any number and sorted
     * by it's own rules against other non-numeric values.
     */
    private static mixedSort(a: any, b: any) {
        const numA = Number(a);
        const numB = Number(b);
        if (!isNaN(numA)) {
            if (!isNaN(numB)) {
                return numA - numB;
            } else {
                return 1;
            }
        } else if (!isNaN(numB)) {
            return -1;
        } else {
            if (a > b) {
                return 1;
            }
            if (a < b) {
                return -1;
            }
            return 0;
        }
    }

    public mounted() {
        this.buildFilters();
    }

    /**
     * Build the selection boxes filters
     */
    public buildFilters() {
        if (this.myFilterList) {
            this.filterArray = {};
            this.myFilterList.forEach((filter: any) => {
                const filterName = filter.field;
                const newFilterArray: string[] = [];

                if (this.myDownloadList) {
                    this.myDownloadList.forEach((v: any) => {
                        if (!newFilterArray.includes(v[filterName])) {
                            newFilterArray.push(v[filterName]);
                        }
                    });
                }

                if (filter.hasOwnProperty('type') && filter.type === 'mixed') {
                    this.filterArray[filterName] = newFilterArray.sort(FilteredDownload.mixedSort);
                } else {
                    this.filterArray[filterName] = newFilterArray.sort();
                }
                if (filter.hasOwnProperty('sort') && filter.sort === 'desc') {
                    this.filterArray[filterName] = this.filterArray[filterName].reverse();
                }
            });
        }
    }

    /**
     * Add each selection box filter to the filters list and apply it
     * @param key
     * @param value
     */
    public addFilter(key: string, value: any) {
        this.filters[key] = value;
        // Start fresh in case a filter was changed
        this.setFilteredList();
    }

    public clearFilters() {
        this.disableClearFilters = true;
        this.filters = [];
        this.myDownloadList = this.downloadList;
        this.filteredDownloadList = [];
        this.selectedFilter = [];
        this.buildFilters();
    }

    public setActiveSelectKey(index: number): void {
        this.activeSelectKey = index;
    }

    public created(): void {
        window.addEventListener('scroll', this.blurSelectOnScroll);
    }

    public destroyed(): void {
        document.removeEventListener('scroll', this.blurSelectOnScroll);
    }

    private blurSelectOnScroll(): void {
        const activeSelect: any = this.$refs[`select_${this.activeSelectKey}`];

        if (activeSelect[0]) {
            activeSelect[0].blur();
        }
    }

    private setFilteredList() {
        if (!this.downloadList) {
            return;
        }
        this.myDownloadList = this.downloadList;
        // This.filteredDownloadList = this.myDownloadList;
        // FIXME: maybe can map this instead of the looping filters - this works for now
        let count = 0;
        for (const k in this.filters) {
            if (this.filters[k]) {
                const v = this.filters[k];
                this.myDownloadList = this.myDownloadList.filter((e: any) => e[k] === v);
                // Console.log(v);
                count++;
            }
        }
        if (count > 0) {
            this.filteredDownloadList = this.myDownloadList;
            this.disableClearFilters = false;
        } else {
            this.filteredDownloadList = null;
            this.disableClearFilters = true;
        }
        this.buildFilters();
    }
}

export default FilteredDownload;
