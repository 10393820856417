































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import SystemApi from '@/services/api/System';
import FilteredDownload from '@/components/Widgets/FilteredDownload.vue';
import axios from '@/services/api/Common';
import { DownloadInterface } from '@/interfaces/DownloadInterface';
import DownloadLink from '@/components/Widgets/DownloadLink.vue';

@Component({
    components: {
        filteredDownload: FilteredDownload,
        downloadLink: DownloadLink,
    },
})
class SystemDownloadsComponent extends Vue {
    public name: string;
    public downloads: any[] = [];
    public processing: boolean = true;
    public error: boolean = false;
    public message: string = '';
    public messageColor: string = '';
    public notFoundMessage: string = '';
    public errorMessageColor: string = '';
    public serverErrorMessage: string = '';
    public downloadNotFound: string = '';
    public progressBar: {
        value: number;
        indeterminate: boolean;
        message: string;
    } = {
        value: 0,
        indeterminate: true,
        message: '',
    };
    public backend: string;

    @Prop()
    public systemId!: string;

    public constructor() {
        super();
        this.name = 'systemDownloads';
        this.notFoundMessage = 'No data available';
        this.errorMessageColor = 'red';
        this.serverErrorMessage = 'Server error';
        this.downloadNotFound = 'File does not exist';
        this.backend = this.$store.getters['siteConfigState/getBackend'];
    }

    public mounted() {
        if (this.systemId) {
            this.progressBar.indeterminate = true;
            this.progressBar.message = 'Getting downloads...';

            SystemApi.getDownloadsList(this.systemId).then((response: any) => {
                this.processing = false;
                this.downloads = response.data.download_groups;
            }).catch((error: any) => {
                this.processing = false;
                this.error = true;
                if (error.response && error.response.status === 404) {
                    this.message = this.notFoundMessage;
                    this.messageColor = 'black';
                } else {
                    this.message = this.serverErrorMessage;
                    this.messageColor = this.errorMessageColor;
                }

            });
        }
    }

    public onDownloadProgress(downloadEvent: any) {
        const downloadLength = downloadEvent.lengthComputable ?
            downloadEvent.total :
            downloadEvent.target.getResponseHeader('content-length');
        this.progressBar.message = this.progressBar.message.replace(
            'Preparing download for',
            'Downloading');
        if (downloadLength !== null) {
            this.progressBar.indeterminate = false;
            this.progressBar.value = Math.round((downloadEvent.loaded * 100) / downloadLength);
        } else {
            this.progressBar.indeterminate = true;
        }
    }

    public getAxios() {
        return axios.create({
            withCredentials: true,
            onDownloadProgress: progressEvent => {
                this.onDownloadProgress(progressEvent);
            },
        });
    }

    public getDownload(download: DownloadInterface) {
        if (download.disabled) {
            return;
        }

        window.open(this.backend + '/api/v2/systems/' + this.systemId + '/binary-downloads/' + download.id, '_blank');
    }
}

export default SystemDownloadsComponent;
