















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SystemInterface } from '../../interfaces/SystemInterface';
import ReportList from '@/components/LicensingReport/ReportList.vue';

@Component({
    components: {
        ReportList,
    },
})
export default class SystemLicensingDialog extends Vue {
    @Prop({ required: true, type: Object as () => SystemInterface })
    public system!: SystemInterface;
    public dialog: boolean = false;
}
