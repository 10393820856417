













































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
class DownloadLink extends Vue {
    @Prop()
    public download!: any;

    @Prop()
    public getDownload!: () => void;
}

export default DownloadLink;
