


































import SystemApi from '@/services/api/System';
import { Component } from 'vue-property-decorator';
import BaseUploader from '@/components/base/BaseUploader';

@Component
class AddByLicense extends BaseUploader {
    public readonly name: string = 'AddByLicense';

    public uploadFile() {
        if (this.fileObj) {
            SystemApi.addSystemWithLicense(this.fileObj)
                .then(response => {

                    const result = {
                        success: true,
                        response,
                    };
                    this.$emit('uploaded', result);

                })
                .catch(error => {

                    let result = null;
                    if ('response' in error) {
                        result = {
                            success: false,
                            response: error.response,
                        };
                    } else {
                        if (error.statusCode === 413) {
                            result = {
                                success: false,
                                response: error.statusMessage,
                            };
                        } else {
                            result = {
                                success: false,
                                response: {
                                    data: {
                                        message: 'Internal error',
                                    },
                                },
                            };
                        }
                    }

                    this.$emit('uploaded', result);
                });
        }
    }
}

export default AddByLicense;
