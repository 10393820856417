


















































































import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import SystemDetailsPaneComponent from './DetailsPane.vue';
import AddByLicense from './AddByLicense.vue';
import SystemApi from '../../services/api/System';

@Component({
    components: {
        systemDetails: SystemDetailsPaneComponent,
        addByLicense: AddByLicense,
    },
})
class List extends Vue {
    @Prop()
    public pageType!: string;
    public sortOrders: any = {};
    public headers: object = {
        'all': { title: 'All', icon: 'mdi-checkbox-multiple-marked' },
        'connect': { title: 'Connect', icon: 'mdi-transit-connection-variant' },
        'appliance': { title: 'Appliances', icon: 'mdi-dns' },
        'stornext software': { title: 'StorNext Software', icon: 'mdi-refresh' },
    };
    public columns: any[] = [
        { value: 'icon', icon: 'mdi-file' },
        { value: 'name', text: 'Name' },
        { value: 'serial', text: 'Serial Number'},
        { value: 'type', text: 'Type' },
        { value: 'owner', text: 'Owner' },
    ];
    public singleExpand: boolean = false;
    public showDetails: boolean = true;
    public expanded: any[] = [''];
    public showAddLicense: boolean = false;
    public sortKey: string = 'name';
    public length: number = 10;
    public search: string = '';
    public typeSearch: string = '';
    public system: any = {};
    public processing: boolean = false;
    public typeFilter: string = 'all';
    public rowsPerPageItems: any[] = [
        10, 20, 50,
        {text: '$vuetify.dataIterator.rowsPerPageAll', value: -1},
    ];
    public systems: any[] = [];
    public offsetTop: number = 0;
    public detailDivStyle: string = '';
    public pagination: any = {};

    public created(): void {
        this.getSystems();
    }

    public mounted(): void {
        this.searchByType(this.pageType);

        if (! this.showOwnerColumn()) {
            this.columns.splice(4, 1);
        }
    }

    public isStorNextUpgradeAvailable(): boolean {
        return ['StorNext Software', 'Appliance'].includes(this.system.type);
    }

    public showOwnerColumn(): boolean {
        return ['appliance', 'stornext software', 'all'].includes(this.pageType);
    }

    public getOwner(owner: any): string {
        return owner ? owner : 'N/A';
    }

    // Computed property
    public get filteredSystems() {
        let systems = this.systems;

        if (this.search) {
            this.search = this.search.replace(/\s+/g, '');

            systems = systems.filter(row =>
                Object.keys(row).some(key => String(row[key]).toLowerCase().indexOf(this.search.toLowerCase()) > -1),
            );
        }

        if (this.typeSearch) {
            systems = systems.filter(
                row => String(row.type).toLowerCase().indexOf(this.typeSearch.trim().toLowerCase()) > -1,
            );
        }

        const sortKey = this.sortKey;
        const order = this.sortOrders[sortKey] || 1;

        if (sortKey && systems) {
            systems = systems.slice().sort((a, b) => {
                a = String(a[sortKey]).toLowerCase();
                b = String(b[sortKey]).toLowerCase();
                return (a === b ? 0 : a > b ? 1 : -1) * order;
            });
        }

        return systems;
    }

    public onScroll() {
        let offsetTop: number | null;
        let offset: number;
        if (document && document.scrollingElement) {
            offset = document.scrollingElement.scrollTop;
        } else {
            offset = 0;
        }
        let y: number;
        const myElement = document.getElementById('main-content');
        if (myElement && ('clientHeight' in myElement)) {
            y = myElement.clientHeight;
        } else {
            y = window.innerHeight;
        }

        if (offset < 65) {
            offsetTop = 0;
        } else {
            offsetTop = offset - 65;
        }

        const detailsElement = document.getElementById('details-div');
        if (!detailsElement) {
            return;
        }
        detailsElement.style.height = window.innerHeight + 'px';
        if (offsetTop !== null) {
            delete (detailsElement.style.bottom);
            detailsElement.style.top = offsetTop + 'px';
        }
    }

    public getSystems() {
        this.processing = true;
        this.showDetails = false;
        this.$store.commit('notificationState/setSpinnerMessage', 'Getting systems...');
        SystemApi.getSystems()
            .then(response => {
                this.processing = false;
                this.$store.commit('notificationState/clearSpinnerMessage');
                this.systems = response.data.systems;
            })
            .catch(error => {
                this.processing = false;
                this.$store.commit('notificationState/clearSpinnerMessage');
                this.$store.commit('notification/setErrorMessage', 'Unable to load systems: ' + error);
            });
    }

    public clickedRow(value: string) {
        this.expanded.push(value);
    }

    public openAddSystem() {
        this.showAddLicense = true;
    }

    public setErrorMessage(message: string) {
        this.$store.commit('notificationState/setErrorMessage', message);
    }

    public setSuccessMessage(message: string) {
        this.$store.commit('notificationState/setSuccessMessage', message);
    }

    public licenseUpload(results: any) {
        if (results.success) {
            let message = '';
            let errorMsgs: { [index: string]: string[] } | null = null;
            const added = results.response.data.added_serials;
            const invalid = results.response.data.invalid_serials;
            const already = results.response.data.already_added;

            // If the API doesn't return error_messages, just leave it null
            if (results.response.data.hasOwnProperty('error_messages')) {
                errorMsgs = results.response.data.error_messages;
            }

            if (added.length > 0) {
                message += 'Added: ' + added.join(',') + ' ';
                this.getSystems();
            }

            /* FIXME: This will currently put the whole message on a single line.
          * We don't currently have a good way to format the notification message, but at least there should be
          * useful info in the notification now.  In *most* cases this will only be a single line anyway.
          */
            if (errorMsgs) {
                let msg = '';
                Object.keys(errorMsgs).forEach((key: string) => {
                    if (errorMsgs && errorMsgs[key]) {
                        let thisMsg = key + ': ';
                        thisMsg += errorMsgs[key].join('. ');
                        if (msg.length > 0) {
                            msg += thisMsg;
                        } else {
                            msg = thisMsg;
                        }
                    }
                });

                this.setErrorMessage(msg);
            } else if (invalid.length > 0) {
                this.setErrorMessage('Invalid serial numbers found: ' + invalid.join(',') + ' ');
            }
            if (already.length > 0) {
                message += 'Already in account: ' + already.join(',');
            }
            this.setSuccessMessage(message);
        } else {
            this.setErrorMessage('Error processing license: ' + results.response.data.message);
        }
        this.showAddLicense = !this.showAddLicense;
    }

    public openSideBar(system: any) {
        this.showDetails = true;
        this.system = system;
    // This.$nextTick(() => {
        // This.onScroll();
        // });
    }

    public searchByType(type: string) {
        this.typeSearch = type !== 'all' ? type : '';
        this.typeFilter = type;
        this.setPaginationPage();
    }

    public setPaginationPage(page: number = 1): void {
        this.pagination.page = page;
    }
}

export default List;
